@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes toast {
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes revealRTL {
  0% {
    width: 0;
    right: 0;
  }
  65% {
    width: 100%;
    right: 0;
  }
  100% {
    width: 0;
    right: 100%;
  }
}

@keyframes revealLTR {
  0% {
    width: 0;
    left: 0;
  }
  65% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes textHidden {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

$pulseRad: 30px;

@keyframes pulse {
  to {
    box-shadow: 0 0 0 $pulseRad rgba($color-black, 0);
  }
}

@keyframes pulseDelayed {
  0% {
  }
  10% {
    box-shadow: 0 0 0 $pulseRad rgba($color-black, 0);
  }
  20% {
    box-shadow: 0 0 0 $pulseRad rgba($color-black, 0);
  }
  30% {
    box-shadow: 0 0 0 $pulseRad rgba($color-black, 0);
  }
  40% {
    box-shadow: 0 0 0 $pulseRad rgba($color-black, 0);
  }
  50% {
    box-shadow: 0 0 0 $pulseRad rgba($color-black, 0);
  }
  60% {
  }
  70% {
  }
  80% {
    box-shadow: 0 0 0 $pulseRad rgba($color-black, 0);
  }
  90% {
    box-shadow: 0 0 0 $pulseRad rgba($color-black, 0);
  }
  100% {
    box-shadow: 0 0 0 $pulseRad rgba($color-black, 0);
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

$btn-width: 250px !default;

@keyframes criss-cross-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    width: calc($btn-width * 1.5);
    height: calc($btn-width * 1.5);
  }
}

@keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    width: calc($btn-width * 1.5);
    height: calc($btn-width * 1.5);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes progress {
  0% {
    transform: scaleX(0);
  }
  30% {
    transform: scaleX(1);
    background-color: $color-navy-blue;
  }

  50% {
    background-color: $color-navy-blue;
  }

  80% {
    background-color: $color-black7;
  }

  100% {
    background-color: $color-black7;
  }
}
