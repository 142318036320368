*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus,
*:hover,
*:active {
  outline: none;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
figure,
blockquote,
dl,
dd {
  margin: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 126px;

  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1;
  }

  ul[class],
  ol[class] {
    padding: 0;
    list-style: none;
  }

  label {
    display: inline-block;
  }

  button,
  span {
    padding: 0;
  }

  a {
    display: inline-block;

    &:not([class]) {
      text-decoration-skip-ink: auto;
    }
  }

  button {
    cursor: pointer;
  }

  img,
  picture {
    max-width: 100%;
    display: block;
  }

  img:not([alt]) {
    filter: blur(10px);
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
}
